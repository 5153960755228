.breadcumb {
  position: relative;
  height: 60vh;
}

.breadcumb::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  /* background-color: var(--primary-color); */
  bottom: 0;
  left: 0;
}

.breadcumb-center {
  color: var(--white-color);
  margin-top: 70px;
  height: 100%;
  transition: var(--transition);
}

.breadcumb-center img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}

.breadcumb-div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.breadcumb-div h1 {
  font-size: var(--font-largest);
  color: var(--white-color);
  text-shadow: 2px 1px var(--blue-color);
}

.breadcumb-div p {
  color: var(--white-color);
  font-size: var(--font-med);
  width: 85%;
  margin: auto;
  line-height: 25px;
  font-family: var(--inter);
  margin-top: -20px;
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .breadcumb-div p {
    width: 65%;
  }
}

@media (max-width: 768px) {
  .breadcumb-div h1 {
    font-size: var(--font-larger);
  }

  .breadcumb-div p {
    width: 100%;
  }

  .breadcumb-div {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .breadcumb-div h1 {
    font-size: var(--font-biggest);
    margin-bottom: 30px;
  }

  .breadcumb-div {
    width: 98%;
  }

  .breadcumb-div p {
    width: 100%;
    font-size: var(--font-smallest);
  }
}
