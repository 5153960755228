.get {
  padding: 150px 10px;
}

.getstarted {
  padding: 100px 10px;
  border-radius: 15px;
  background: var(--card-color);
  width: 80%;
  margin: auto;
  text-align: center;
}

.getstarted h3 {
  color: var(--text-gray);
}

.getstarted h1 {
  color: var(--white-color);
  font-size: var(--font-larger);
  font-family: var(--inter);
}

.getstarted p {
  color: var(--white-color);
  line-height: 25px;
  width: 76%;
  margin: 30px auto;
  font-size: var(--font-med);
}

.getstarted button {
  background: var(--text-started);
  border: none;
  border-radius: 25px;
  color: var(--text-started-button);
  outline: none;
  text-transform: uppercase;
  padding: 13px 30px;
  font-weight: 600;
  transition: var(--transition);
  cursor: pointer;
  font-size: var(--font-smallest);
}

/* .getstarted button:hover {
  background: var(--primary-color);
  transition: var(--transition);
  color: var(--white-color);
} */

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .getstarted {
    padding: 120px 10px;
    width: 70%;
  }

  .getstarted h1 {
    font-size: var(--font-largest);
  }

  .getstarted p {
    width: 56%;
  }

  .getstarted button {
    padding: 15px 35px;
  }
}

@media (max-width: 768px) {
  .get {
    padding: 80px 10px;
  }
  .getstarted {
    width: 90%;
  }

  .getstarted h3 {
    font-size: var(--font-mid);
  }

  .getstarted h1 {
    font-size: var(--font-large);
  }

  .getstarted p {
    font-size: var(--font-mid);
    width: 90%;
  }
}

@media (max-width: 500px) {
  .getstarted {
    width: 100%;
  }

  .getstarted h1 {
    font-size: var(--font-biggest);
  }

  .getstarted p {
    font-size: var(--font-smallest);
    width: 100%;
  }

  .getstarted button {
    font-size: var(--font-smaller);
  }
}
