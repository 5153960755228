.any-countrys {
  padding: 90px 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: var(--secondary-color);
}

.country-left {
  width: 50%;
}

.country-left h3 {
  font-size: var(--font-largest);
  font-weight: 600;
  color: var(--white-color);
}

.country-left h2 {
  font-size: var(--font-largest);
  font-weight: 800;
  margin-bottom: 20px;
  color: var(--tertiary-color);
}

.country-left p {
  line-height: 30px;
  color: var(--white-color);
  margin-bottom: 20px;
  font-size: var(--font-med);
}

.country-left button {
  background: var(--blue-color);
  padding: 15px 35px;
  color: var(--white-color);
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: var(--transition);
}

.country-left button:hover {
  background: var(--tertiary-color);
}

.country-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .country-left {
    width: 35%;
  }

  .country-right {
    width: 30%;
  }
}

@media (max-width: 900px) {
  .country-right img {
    height: 70%;
    width: 70%;
  }
}

@media (max-width: 768px) {
  .country-left h3 {
    font-size: var(--font-large);
  }

  .country-left h2 {
    font-size: var(--font-larger);
  }

  .country-left p {
    font-size: var(--font-mid);
  }
}

@media (max-width: 600px) {
  .any-countrys {
    padding: 90px 10px;
  }

  .country-left {
    width: 100%;
  }

  .country-right {
    display: none;
  }

  .country-left button {
    padding: 13px 30px;
    font-size: var(--font-smaller);
  }
}
