.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 9999;
  background: var(--nav-color);
  box-shadow: var(--shadow);
  padding: 10px 20px;
}

.nav-center {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-header img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.header-brand {
  font-weight: 700;
  text-align: center;
  margin-left: 5px;
  color: var(--primary-color);
  margin-top: 5px;
  font-size: var(--font-smaller);
}

.nav-header small {
  display: block;
}

/* THE TOGGLE BUTTON */
.toggle-icon {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--white);
  cursor: pointer;
  transition: var(--transition);
  outline: none;
  margin-right: 2rem;
  display: none;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links li {
  margin-right: 3rem;
  font-family: var(--inter);
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links a {
  text-transform: capitalize;
  color: var(--primary-color);
  letter-spacing: var(--spacing);
  transition: var(--transition);
  font-size: var(--font-smallest);
  font-weight: 600;
  font-family: var(--inter);
  text-transform: uppercase;
}

.nav-links a:hover {
  color: var(--blue-color);
  transition: var(--transition);
}

.nav-links .active {
  color: var(--blue-color);
  font-weight: 700;
}

.nav-display {
  display: flex;
  align-items: center;
  color: var(--primary-color);
}

.nav-display p {
  font-size: var(--font-mid);
  font-weight: 600;
  display: block;
}

.nav-display .telephone {
  margin-right: 10px;
  margin-bottom: -2px;
}

.nav-display .toggle {
  margin: 0 0 0 55px;
  font-size: var(--font-biggest);
  cursor: pointer;
}

/* THE SECTION OF THE MEDIA QUERY FROM 768PX UPWARD */
@media screen and (min-width: 1240px) {
  .nav-links a {
    font-size: var(--font-smallest);
  }

  .nav-display p {
    font-size: var(--font-mid);
  }
}

@media (max-width: 950px) {
  .navbar {
    padding: 10px;
  }

  .nav-header img {
    width: 50px;
    height: 50px;
  }

  .header-brand {
    font-size: var(--font-small);
    margin-left: 3px;
  }

  .nav-links li {
    margin-right: 1.5rem;
  }

  .nav-links li:last-child {
    margin-right: 0;
  }

  .nav-links a {
    font-size: var(--font-smaller);
  }

  .nav-display p {
    font-size: var(--font-smaller);
  }

  .nav-display .telephone {
    margin-right: 8px;
  }

  .nav-display .toggle {
    font-size: var(--font-medium);
  }
}

@media (max-width: 768px) {
  .toggle-icon {
    display: block;
    margin-right: 0;
    margin-top: 5px;
  }

  .nav-links {
    display: none;
  }

  .nav-display p {
    display: none;
  }

  .nav-display .toggle {
    margin-top: 5px;
  }
}

@media (max-width: 310px) {
  .nav-display .toggle {
    display: none;
  }
}
