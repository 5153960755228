/*
==================================
THE SECTION OF THE SIDE BAR
==================================
*/
.sidebar {
  background: var(--nav-color);
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: grid;
  opacity: 0;
  transition: var(--transition);
  transform: translateX(-100%);
}

.show-sidebar {
  opacity: 1;
  transform: translateX(-20%);
}

.sidebar-links {
  margin-top: 100px;
}

.sidebar-links li {
  opacity: 0;
}

.sidebar-links li a {
  color: var(--primary-color);
  line-height: 60px;
  font-weight: 500;
  font-size: 1.2rem;
  /* border-bottom: 2px solid var(--secondary-color); */
}

.close-btn {
  position: absolute;
  right: 3%;
  top: 2.75%;
  font-size: 1rem;
  background: transparent;
  border-color: transparent;
  color: var(--white);
  cursor: pointer;
  outline: none;
}

/*
  ===============
  Sidebar Animation
  ===============
  */
.sidebar-links li {
  animation: slideLeft 0.5s ease-in-out 0.3s forwards;
}
.sidebar-links li:nth-of-type(1) {
  animation-delay: 0.25s;
}
.sidebar-links li:nth-of-type(2) {
  animation-delay: 0.5s;
}
.sidebar-links li:nth-of-type(3) {
  animation-delay: 0.75s;
}
.sidebar-links li:nth-of-type(4) {
  animation-delay: 1s;
}
.sidebar-links li:nth-of-type(5) {
  animation-delay: 1.25s;
}
@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(50%);
    opacity: 1;
  }
}

/* =============================== */

/* THE RESPONSIVENESS */
@media (max-width: 425px) {
  .sidebar-links li a {
    font-size: var(--font-mid);
  }

  .toggle-icon {
    margin-top: 10px;
    font-size: var(--font-biggest);
  }

  @keyframes slideLeft {
    100% {
      transform: translateX(50%);
      opacity: 1;
    }
  }
}
