.plan {
  padding: 150px 10px;
  background: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.plan-left {
  width: 50%;
}

.plan-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.plan-right {
  width: 50%;
}

.plan-right h2 {
  font-size: var(--font-larger);
  color: var(--white);
}

.plan-right h2 b {
  color: var(--blue-color);
}

.plan-right h2 b span {
  color: var(--tertiary-color);
}

.plan-right p {
  color: var(--text-light);
  margin-top: 15px;
  line-height: 25px;
  margin-bottom: 15px;
  font-size: var(--font-med);
}

.plan-right button {
  background: var(--blue-color);
  color: var(--white-color);
  padding: 15px 40px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  transition: var(--transition);
}

.plan-right button:hover {
  background: var(--tertiary-color);
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .plan-left {
    width: 40%;
  }

  .plan-right {
    width: 40%;
  }

  .plan-right h2 b {
    font-size: var(--font-largest);
  }
}

@media (max-width: 900px) {
  .plan-left img {
    width: 85%;
    height: 85%;
  }

  .plan-right h2 {
    font-size: var(--font-large);
  }

  .plan-right p {
    font-size: var(--font-mid);
  }

  .plan-right button {
    padding: 13px 30px;
    font-size: var(--font-smaller);
  }
}

@media (max-width: 600px) {
  .plan {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .plan-left {
    width: 100%;
  }

  .plan-left img {
    width: 100%;
    height: 100%;
  }

  .plan-right {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
}
