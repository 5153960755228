@import url("https://fonts.googleapis.com/css?family=DM Sans:300,400,700,900");

.slider-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

.slide {
  height: 100vh;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.945))
  );
  background: linear-gradient(transparent, rgb(3, 3, 3));
  bottom: 0;
  left: 0;
}

.previousButton {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 4.5%;
  z-index: 10;
  background: url("https://res.cloudinary.com/devsource/image/upload/v1621513364/Lighthill/left-arrowhead-in-a-circle_de2wt6.svg")
    no-repeat center center / 56px;
  width: 28px;
  height: 28px;
  text-indent: -9999px;
  cursor: pointer;
  /* background: red; */
}

.nextButton {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 2% !important;
  z-index: 10;
  background: url("https://res.cloudinary.com/devsource/image/upload/v1621513554/Lighthill/right-arrow-in-circular-button_1_js8m5b.svg")
    no-repeat center center / 56px;
  width: 28px;
  height: 28px;
  text-indent: -9999px;
  cursor: pointer;
}

.nextButton {
  right: 0;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* .slider-content * {
  pointer-events: none;
} */

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h4 {
  font-size: var(--font-med);
  font-weight: 500;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom: 20px;
}

.slider-content .inner h1 {
  font-weight: 900;
  max-width: 640px;
  color: #ffffff;
  font-size: 50px;
  margin: 10px auto;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: var(--font-med);
  line-height: 30px;
  max-width: 500px;
  margin: 20px auto;
  font-weight: 700;
}

.slider-content .inner button {
  background: var(--tertiary-color);
  color: var(--white-color);
  padding: 12px 30px;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: var(--transition);
}

.slider-content .inner button:hover {
  background: var(--blue-color);
}

.inner h4 {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .slider-wrapper {
    position: relative;
    height: 100vh;
    overflow: auto;
    width: 100%;
  }

  .sidebar-content .inner h4 {
    font-size: 10px;
    display: none !important;
  }

  .slider-content .inner h1 {
    font-size: 38px;
    width: 90%;
    margin: 0 auto;
  }

  .slider-content .inner p {
    font-size: 15px;
  }

  .previousButton {
    left: 6%;
  }
}

@media screen and (max-width: 600px) {
  .slider-content .inner h1 {
    font-size: 28px;
  }

  .sidebar-content .inner h4 {
    font-size: 10px !important;
  }

  .nextButton,
  .previousButton {
    top: 70%;
  }
  .previousButton {
    left: 7%;
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

/* THE SECTION OF THE MEDIA QUERY BELOW 425PX */
@media screen and (min-width: 100px) and (max-width: 320px) {
  .slider-content .inner {
    padding: 0 30px;
  }

  .slider-content .inner p {
    font-size: 16px;
  }
  .previousButton {
    left: 11%;
  }
}

@media screen and (min-width: 321px) and (max-width: 425px) {
  .slider-content .inner {
    padding: 0 30px;
  }

  .slider-content .inner p {
    font-size: 16px;
  }

  .inner button {
    font-size: 12px;
  }

  .previousButton {
    left: 9%;
  }
}
