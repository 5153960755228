/* testimonial-section */
.testimonial-section {
  margin: 0 auto;
  background: var(--grey-color);
  padding: 80px 10px;
}

.testimonial-title {
  text-align: center;
  margin-bottom: 2rem;
  font-family: DM Sans;
}

.testimonial-title h3 {
  color: var(--blue-color);
  font-family: var(--inter);
}

.testimonial-title h1 {
  font-size: var(--font-larger);
  font-family: var(--inter);
  color: var(--text-testimonial);
}

.testimonial-title p {
  color: var(--text-light);
  line-height: 30px;
  margin: auto;
  width: 45%;
  font-family: var(--inter);
  font-weight: 600;
}

.testimonial-center {
  margin: 0 auto;
  margin-top: 2rem;
  width: 80vw;
  height: 300px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
  font-weight: bold;
}

.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid var(--primary-color);
  box-shadow: var(--shadow);
}

.test-title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: var(--primary-color);
}

.test-text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
  color: var(--primary-color);
  padding: 0 20px;
}

.icon {
  font-size: 2rem;
  margin-top: 1rem;
  color: var(--blue-color);
}

.navigate-left {
  width: 40px;
  height: 40px;
  background: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  left: 0;
}

.navigate-right {
  width: 40px;
  height: 40px;
  background: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  right: 15px;
}

.pre,
.nex {
  color: var(--nav-color);
  transition: var(--transition);
  font-size: var(--font-med);
}

@media (min-width: 800px) {
  .test-text {
    max-width: 30em;
  }

  .pre,
  .nex {
    font-size: var(--font-med);
  }
}

/* ============================================ */
main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}

main h4 {
  text-transform: uppercase;
  color: var(--primary-color);
  margin-bottom: 0.25rem;
}

main.activeSlide {
  opacity: 1;
  transform: translateX(0);
  transition: var(--transition);
}
main.lastSlide {
  transform: translateX(-100%);
  transition: var(--transition);
}
main.nextSlide {
  transform: translateX(100%);
  transition: var(--transition);
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .testimonial-title h1 {
    font-size: var(--font-largest);
  }

  .navigate-left,
  .navigate-right {
    height: 50px;
    width: 50px;
  }
}

@media (max-width: 768px) {
  .testimonial-title h3 {
    font-size: var(--font-mid);
  }

  .testimonial-title h1 {
    font-size: var(--font-large);
  }

  .testimonial-title p {
    width: 80%;
  }

  .testimonial-center {
    margin-top: 4rem;
  }
}

@media (max-width: 500px) {
  .testimonial-title h1 {
    font-size: var(--font-bigger);
  }

  .testimonial-title p {
    width: 100%;
    font-size: var(--font-smallest);
  }

  .testimonial-center {
    width: 100%;
  }

  .navigate-left {
    left: 15px;
    top: 250px;
  }

  .navigate-right {
    top: 250px;
  }
}

@media (max-width: 320px) {
  .navigate-left,
  .navigate-right {
    display: none;
  }
}
