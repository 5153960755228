.prepare {
  padding: 80px 10px;
  text-align: center;
  /* background:orange; */
}

.prepare h1 {
  font-size: var(--font-larger);
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--white);
}

.prepare p {
  color: var(--text-light);
  font-size: var(--font-med);
  width: 80%;
  margin: auto;
}

.prepare-center {
  margin: 100px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.prepare-card {
  width: 30%;
}

.prepare-center h2 {
  color: var(--white);
  font-size: var(--font-big);
}

.prepare-center small {
  width: 90%;
  margin: 15px auto;
  display: block;
  line-height: 25px;
  color: var(--text-light);
  font-size: var(--font-med);
}

/* ========================================== */
/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .prepare p {
    width: 50%;
  }

  .prepare-center {
    width: 90%;
  }

  .prepare-center h2 {
    color: var(--white);
    font-size: var(--font-bigger);
  }

  .prepare-center small {
    width: 80%;
  }
}

@media (max-width: 950px) {
  .prepare h1 {
    font-size: var(--font-large);
  }

  .prepare-center h2 {
    color: var(--white);
    font-size: var(--font-medium);
  }

  .prepare-center small {
    font-size: var(--font-mid);
  }
}

@media (max-width: 800px) {
  .prepare-center {
    flex-wrap: wrap;
    max-width: 800px;
  }

  .prepare-card {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .prepare h1 {
    font-size: var(--font-biggest);
  }

  .prepare-card {
    width: 100%;
  }
}
