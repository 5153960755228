.values {
  padding: 100px 10px;
  display: flex;
  align-items: center;
  background: var(--nav-color);
  justify-content: center;
}

.values-center {
  text-align: center;
}

.values-center h3 {
  color: var(--blue-color);
  font-size: var(--font-med);
  text-transform: uppercase;
}

.values-center h1 {
  font-size: 50px;
  color: var(--white);
  font-weight: 800;
  margin-top: -2px;
}

.values-center p {
  font-size: var(--font-mid);
  color: var(--text-light);
  width: 50%;
  margin: -18px auto 100px auto;
  line-height: 25px;
  font-weight: 600;
}

.values-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}

.values-card {
  width: 30%;
  box-shadow: var(--shadow);
  border-radius: 10px;
  padding: 50px 15px;
  border: 1px solid var(--text-gray);
  height: 400px;
}

.values-card img {
  height: 100px;
  width: 100px;
}

.values-card h2 {
  font-weight: 700;
  font-size: var(--font-bigger);
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--white);
}

.values-card small {
  font-size: var(--font-mid);
  color: var(--text-light);
  line-height: 25px;
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .values-center h3 {
    font-size: var(--font-medium);
  }

  .values-center h1 {
    font-size: 60px;
  }

  .values-center p {
    font-size: var(--font-med);
  }

  .values-div {
    width: 80%;
  }

  .values-card {
    padding: 50px 30px;
  }
}

@media (max-width: 1000px) {
  .values-div {
    width: 100%;
  }

  .values-card {
    width: 32%;
    padding: 50px 10px;
  }
}

@media (max-width: 800px) {
  .values-center h3 {
    font-size: var(--font-mid);
  }

  .values-center h1 {
    font-size: var(--font-larger);
  }
  .values-center p {
    width: 80%;
  }

  .values-card {
    width: 47%;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .values-center p {
    width: 100%;
  }

  .values-card {
    width: 80%;
    margin: 0 auto 20px auto;
  }
}

@media (max-width: 425px) {
  .values-center h3 {
    font-size: var(--font-smaller);
  }

  .values-center h1 {
    font-size: var(--font-large);
    margin-bottom: 30px;
  }

  .values-center p {
    font-size: var(--font-smaller);
  }

  .values-card {
    width: 100%;
  }
}
