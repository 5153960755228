.contact {
  /* width:80%;
    margin:50px auto; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 80px 10px;
  background: var(--text-grey-color);
}

.contact-card {
  background: var(--background-color);
  box-shadow: var(--shadow);
  padding: 30px;
  width: 300px;
  height: 290px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  transition: var(--transition);
  border: 3px solid transparent;
  /* border-bottom: 3px solid var(--tertiary-color); */
}

.contact-card:hover {
  transform: scale(1);
  border: 3px solid var(--blue-color);
  transition: var(--transition);
}

.contact img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.contact-card h2 {
  font-size: var(--font-biggest);
  color: var(--white);
  margin-top: -3px;
  margin-bottom: 15px;
}

.contact-card small {
  font-weight: 700;
  line-height: 25px;
  display: block;
  font-size: var(--font-smallest);
  color: var(--text-light);
}

.contact-social {
  width: 180px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.social-div {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
}

.contact-icon {
  font-size: var(--font-big);
  color: var(--white);
}

.social-div:hover {
  background: var(--primary-color);
}

.social-div:hover .contact-icon {
  color: var(--nav-color);
  transition: var(--transition);
}

/* THE RESPONSIVENESS */
@media (max-width: 1000px) {
  .contact {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .contact-card {
    margin-bottom: 30px;
  }
}
