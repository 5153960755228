.services {
  padding: 70px 10px;
  text-align: center;
}

.services h3 {
  color: var(--blue-color);
  font-size: var(--font-larger);
  text-transform: uppercase;
}

.services p {
  width: 40%;
  color: var(--text-light);
  margin: 0 auto;
  margin-top: 15px;
  line-height: 25px;
  font-weight: 600;
}

/*  */
.services-center {
  margin: 90px auto;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.services-card {
  border: 1px solid var(--gray-color);
  border-radius: 5px;
  text-align: center;
  padding: 40px 10px;
}

.services-card h2 {
  font-size: var(--font-big);
  color: var(--white);
  margin-bottom: 10px;
}

.services-card small {
  font-size: var(--font-mid);
  color: var(--text-color);
  line-height: 25px;
  color: var(--text-light);
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .services h3 {
    font-size: var(--font-larger);
  }

  /*  */
  .services-center {
    margin: 90px auto;
    width: 70%;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }

  .services-card {
    padding: 40px 20px;
  }

  .services-card h2 {
    font-size: var(--font-big);
  }

  .services-card small {
    font-size: var(--font-mid);
  }
}

@media (max-width: 1000px) {
  .services-center {
    width: 95%;
  }
}

@media (max-width: 800px) {
  .services-center {
    grid-template-columns: repeat(2, 1fr);
  }
  .services h3 {
    font-size: var(--font-biggest);
  }

  .services p {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .services-center {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
