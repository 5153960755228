.experience {
  padding: 150px 10px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.experience-left {
  width: 40%;
}

.experience-left h3 {
  color: var(--blue-color);
  font-size: var(--font-big);
  margin-bottom: 15px;
}

.experience-left h1 {
  font-size: var(--font-larger);
  width: 70%;
  font-family: var(--inter);
  color: var(--white);
  line-height: 55px;
  margin-top: -1px;
}

.experience-left p {
  color: var(--text-light);
  line-height: 25px;
  margin-top: -15px;
  margin-bottom: 20px;
  font-size: var(--font-mid);
}

.experience-left button {
  background: var(--blue-color);
  color: var(--white-color);
  padding: 14px 35px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

/* ------------------------------ */
.experience-right {
  width: 40%;
}

.experience-right img {
  width: 100%;
  object-fit: cover;
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .experience {
    padding: 150px 10px;
  }

  .experience-left {
    width: 40%;
  }

  .experience-left h3 {
    font-size: var(--font-big);
  }

  .experience-left h1 {
    font-size: var(--font-largest);
    width: 70%;
    margin-top: -1px;
  }

  .experience-left p {
    font-size: var(--font-mid);
  }

  /* ------------------------------ */
  .experience-right {
    width: 40%;
  }
}
@media (max-width: 1000px) {
  .experience-left {
    width: 50%;
  }

  .experience-right {
    width: 45%;
  }
}

@media (max-width: 800px) {
  .experience {
    flex-direction: column-reverse;
    padding: 150px 20px;
  }

  .experience-left {
    width: 100%;
    margin-top: 30px;
  }

  .experience-left h1 {
    font-size: var(--font-large);
  }

  .experience-right {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .experience {
    padding: 150px 10px;
  }
  .experience-left h3 {
    font-size: var(--font-med);
  }
  .experience-left h1 {
    width: 100%;
  }
}
