.lets-talk {
  padding: 80px 10px 100px 10px;
  background: var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.lets-talk-center {
  color: var(--white-color);
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: space-around;
}

.lets-talk-center h2 {
  font-size: var(--font-medium);
  font-family: var(--inter);
  font-weight: 600;
}

.lets-talk-center button {
  padding: 14px 35px;
  background: transparent;
  border: 1px solid var(--white-color);
  border-radius: 5px;
  color: var(--white-color);
  cursor: pointer;
  transition: var(--transition);
}

.lets-talk-center button:hover {
  background: var(--secondary-color);
  transition: var(--transition);
}

/* THE RESPONSIVENESS */

@media (max-width: 900px) {
  .lets-talk-center {
    width: 100%;
  }

  .lets-talk-center h2 {
    font-size: var(--font-med);
  }
}

@media (max-width: 600px) {
  .lets-talk {
    height: 100%;
  }
  .lets-talk-center {
    flex-direction: column;
  }

  .lets-talk-center h2 {
    margin-bottom: 20px;
    text-align: center;
    line-height: 30px;
    font-size: var(--font-mid);
  }

  .lets-talk-center button {
    margin-bottom: 50px;
  }
}
