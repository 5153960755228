.about {
  padding: 90px 10px 50px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.about-left {
  max-width: 500px;
  height: 400px;
  width: 100%;
}

.about-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*  */
.about-right {
  width: 63%;
  padding-left: 20px;
}

.about-right h3 {
  color: var(--blue-color);
}

.about-right h1 {
  font-size: var(--font-largest);
  margin-top: -5px;
  width: 70%;
  font-family: var(--inter);
  color: var(--primary-color);
  line-height: 65px;
}

.about-right h1 span {
  color: var(--tertiary-color);
}

.about-right p {
  line-height: 30px;
  color: var(--text-light);
  margin-top: -15px;
  width: 95%;
}

/* THE RESPONSIVENESS */
@media (min-width: 1380px) {
  .about-right {
    width: 63%;
  }

  .about-right h1 {
    width: 50%;
  }

  .about-right h1 span {
    color: var(--tertiary-color);
  }

  .about-right p {
    line-height: 30px;
    color: var(--text-light);
    margin-top: -15px;
    width: 95%;
  }
}

@media (max-width: 1190px) {
  .about-left {
    height: 500px;
  }

  .about-right h1 {
    font-size: var(--font-larger);
    line-height: 50px;
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .about-left {
    max-width: 350px;
  }
}

@media (max-width: 823px) {
  .about-right h1 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .about {
    flex-direction: column-reverse;
  }

  .about-left {
    height: 300px;
    max-width: 100%;
  }

  .about-right {
    width: 100%;
    padding-left: 0;
    margin-bottom: 10px;
  }

  .about-right h1 {
    margin-top: 15px;
  }
}

@media (max-width: 425px) {
  .about-right h1 {
    font-size: var(--font-large);
    line-height: 40px;
  }
}
