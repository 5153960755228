.years {
  padding: 20px 10px 140px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.years-card {
  height: 200px;
  padding: 30px 10px;
  text-align: center;
}

.years-card h1 {
  margin: 20px 0;
  color: var(--blue-color);
  font-family: var(--inter);
}

.years-card p {
  color: var(--text-light);
  font-weight: 600;
}

/* THE RESPONSIVE */
@media (max-width: 820px) {
  .years {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 425px) {
  .years {
    grid-template-columns: 1fr;
  }
}
