@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;600;800&family=Nunito+Sans:wght@400;600;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Nunito Sans";
  background: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #003274;
  --white-color: #ffffff;
  --tertiary-color: #90ce22;
  --secondary-color: #062d55;
  --text-color: #5d5d6d;
  --black-color: #101010;
  --deep-blue-clolor: #13142c;
  --light-color: rgba(255, 255, 255, 0.74);
  --grey-color: #eeeefc;
  --gray-color: #dcdce9;
  --blue-color: #5c34ff;
  --nav-color: #1a1b20;

  /* Font-Families */
  --nunito: "Nunito Sans";
  --inter: "Inter";

  /* Font Sizes */
  --font-small: 11px;
  --font-smaller: 13px;
  --font-smallest: 15px;
  --font-mid: 16px;
  --font-med: 18px;
  --font-medium: 20px;
  --font-big: 22px;
  --font-bigger: 24px;
  --font-biggest: 27px;
  --font-large: 30px;
  --font-larger: 40px;
  --font-largest: 49px;

  --box-shadow: 0px 0px 7px rgba(65, 111, 65, 0.2);
  --shadow: 0px 0px 5px rgba(35, 37, 54, 0.25);
  --transition: all 0.2s ease-in-out;
}

/* Light theme */
.light-theme {
  --primary-color: #003274;
  --white-color: #fff;
  --tertiary-color: #90ce22;
  --secondary-color: #062d55;
  --text-color: #5d5d6d;
  --black-color: #101010;
  --deep-blue-clolor: #13142c;
  --light-color: rgba(255, 255, 255, 0.74);
  --grey-color: #fff;
  --gray-color: #dcdce9;
  --blue-color: #5c34ff;

  /*  */

  --nav-color: #fff;
  --background-color: #fff;
  --card-color: #5c34ff;
  --text-light: #5d5d6d;
  --text-gray: #dcdce9;
  --text-started: #fff;
  --text-testimonial: #062d55;
  --white: #101010;
  --text-grey-color: #eeeefc;
  --text-started-button: #5c34ff;
}

/* dark theme */
.dark-theme {
  --primary-color: #fff;
  --white-color: #ffffff;
  --tertiary-color: #90ce22;
  --secondary-color: #062d55;
  --text-color: #5d5d6d;
  --black-color: #101010;
  --deep-blue-clolor: #13142c;
  --light-color: rgba(255, 255, 255, 0.74);
  --grey-color: #062d55;
  --gray-color: #dcdce9;
  --blue-color: #5c34ff;
  --text-testimonial: #fff;

  /*  */

  --nav-color: #062d55;
  --background-color: #13142c;
  --card-color: #062d55;
  --text-light: #e1e1e7;
  --text-gray: #5c34ff;
  --text-started: #5c34ff;
  --white: #fff;
  --text-grey-color: #062d55;
  --text-started-button: #fff;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

/* ======================================= */
/* Components Styles */
@import "./components/navbar/Navbar.css";
@import "./components/navbar/Sidebar.css";
@import "./components/hero/Hero.css";
@import "./components/contact/Contact.css";
@import "./components/contact2/Contact2.css";
@import "./components/footer/Footer.css";
@import "./components/letstalk/LetsTalk.css";
@import "./components/services/Services.css";
@import "./components/how/How.css";
@import "./components/experience/Experience.css";
@import "./components/about/About.css";
@import "./components/years/Years.css";
@import "./components/why/Why.css";
@import "./components/getstarted/Getstarted.css";
@import "./components/testimonials/Testimonial.css";
@import "./components/prepare/Prepare.css";
@import "./components/plan/Plan.css";
@import "./components/anycountry/AnyCountry.css";
@import "./components/values/Values.css";
@import "./components/ahead/Ahead.css";
@import "./components/vacationspot/VacationSpot.css";
@import "./utils/Loader.css";

/* Common styles */
@import "./common/breadcumb/BreadCumb.css";
