.error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 10px;
}

.error-center h1 {
  font-size: var(--font-largest);
  color: var(--white);
}

.error-center h3 {
  font-size: var(--font-larger);
  color: red;
}

.error-center p {
  color: var(--text-color);
  margin-top: 10px;
}

.error-center button {
  background: var(--card-color);
  padding: 10px 25px;
  border: none;
  color: var(--white-color);
  outline: none;
  margin-top: 30px;
  cursor: pointer;
}
