.how {
  padding: 80px 10px;
  background: var(--secondary-color);
  /* height: 400px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.how h1 {
  font-size: var(--font-larger);
  color: var(--white-color);
}

.how-center {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  background: var(--background-color);
  padding: 30px 20px;
  margin: 40px auto;
  width: 80%;
}

.how-card {
  border: 1px solid var(--gray-color);
  border-radius: 5px;
  text-align: center;
  padding: 40px 10px;
}

.how-card h2 {
  font-size: var(--font-bigger);
  color: var(--white);
  margin: 20px auto;
}

.how-card small {
  font-size: var(--font-mid);
  color: var(--text-light);
  line-height: 25px;
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .how h1 {
    font-size: var(--font-larger);
  }

  .how-center {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    padding: 30px 20px;
    width: 70%;
  }

  .how-card h2 {
    font-size: var(--font-bigger);
  }

  .how-card small {
    font-size: var(--font-mid);
  }
}

@media (max-width: 1000px) {
  .how-center {
    width: 100%;
    grid-gap: 1rem;
  }
}

@media (max-width: 800px) {
  .how h1 {
    font-size: var(--font-large);
  }
  .how-center {
    grid-template-columns: 1fr;
    width: 70%;
  }
}

@media (max-width: 500px) {
  .how-center {
    width: 100%;
  }
}
