.vacation-spot {
  padding: 80px 10px;
  /* background: yellow; */
  text-align: center;
}

.vacation-spot h1 {
  font-size: var(--font-large);
  color: var(--white);
}

.vacation-spot p {
  margin-top: -10px;
  margin-bottom: 60px;
  color: var(--text-light);
  font-size: var(--font-med);
}

.vacation-center {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  grid-row-gap: 3rem;
  width: 100%;
  margin: 0 auto;
}

.vacation-card {
  background: var(--nav-color);
  box-shadow: var(--shadow);
  overflow: hidden;
  border-bottom: 3px solid var(--tertiary-color);
}

.vacation-image {
  height: 300px;
  transition: var(--transition);
}

.vacation-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}

.vacation-image:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: var(--transition);
}

.vacation-div {
  padding: 20px;
  text-align: left;
}

.vacation-div h2 {
  color: var(--white);
  font-size: var(--font-mid);
  line-height: 25px;
  margin-bottom: 5px;
}

.vacation-div small {
  font-size: var(--font-smaller);
  color: var(--text-light);
  display: block;
  margin-bottom: 10px;
}

.vacation-div span {
  color: var(--blue-color);
  cursor: pointer;
  font-weight: 700;
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .vacation-spot h1 {
    font-size: var(--font-larger);
  }

  .vacation-spot p {
    font-size: var(--font-medium);
  }

  .vacation-center {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .vacation-center {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 700px) {
  .vacation-center {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .vacation-center {
    grid-template-columns: 1fr;
  }
}
