.why {
  background: var(--secondary-color);
  padding: 90px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.why-left {
  width: 45%;
}

.why-left h3 {
  color: var(--gray-color);
}

.why-left h1 {
  font-size: var(--font-larger);
  color: var(--white-color);
}

.why-left p {
  color: var(--gray-color);
  margin-top: -10px;
  line-height: 30px;
  font-size: var(--font-med);
  margin-bottom: 20px;
}

.why-left button {
  background: var(--blue-color);
  color: var(--white-color);
  padding: 15px 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

.why-left button:hover {
  background: blue;
  transition: var(--transition);
}

/*  */
.why-right {
  width: 40%;
}

.why-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .why-left {
    width: 45%;
  }

  .why-left h1 {
    font-size: var(--font-largest);
  }

  /*  */
  .why-right {
    width: 40%;
  }
}

@media (max-width: 1000px) {
  .why {
    justify-content: space-between;
  }
  .why-left {
    width: 55%;
  }
}

@media (max-width: 800px) {
  .why {
    flex-direction: column-reverse;
    padding: 90px 20px;
  }

  .why-left {
    width: 100%;
  }

  .why-right {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .why {
    padding: 90px 10px;
  }

  .why-left h3 {
    font-size: var(--font-mid);
  }

  .why-left h1 {
    font-size: var(--font-biggest);
  }

  .why-left p {
    font-size: var(--font-smallest);
  }
}
