.footer {
  padding: 40px 10px;
  background: var(--black-color);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 200px; */
}

.footer-center {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 95%;
  margin: auto;
  font-size: var(--font-smallest);
  height: 100%;
}

.footer-card {
  padding: 0;
}

.footer-center:last-child {
  padding: 0;
}

.footer-card p {
  color: var(--gray-color);
  line-height: 25px;
}

/* .footer-center .card-about {
  padding-top: 0 !important;
  height: 200px;
} */

.footer-card ul {
  text-align: center;
  font-weight: 700;
}

.footer-card ul li {
  margin-bottom: 60px;
  margin-top: 5px;
}

.footer-card li a {
  color: var(--gray-color);
}

.footer-card li a:hover {
  color: var(--blue-color);
}

.footer-data {
  display: flex;
  align-items: center;
  color: var(--gray-color);
  line-height: 25px;
  margin-bottom: 25px;
}

.footer-data .address {
  width: 70%;
}

.footer-data-icons {
  margin-right: 25px;
  font-size: var(--font-big);
}

.footer-card-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.6rem;
}

.card-image {
  /* height: 200px; */
  padding-top: 0 !important;
}

.footer-card-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-bottom {
  display: flex;
  align-items: center;
  padding: 20px 80px;
  justify-content: space-between;
  background: var(--black-color);
  border-top: 1px solid var(--text-color);
}

.footer-social {
  display: flex;
}

.footer-social .social-div {
  margin-right: 15px;
  border: 1px solid var(--white-color);
}

.footer-social .contact-icon {
  color: var(--gray-color);
}

.footer-social .social-div:hover {
  background: var(--blue-color);
}

.footer-social .social-div:hover .contact-icon {
  color: var(--white-color);
  transition: var(--transition);
}

.footer-reserved {
  color: var(--gray-color);
}

/* ===================================== */
/* THE RESPONSIVENESS */
@media (max-width: 900px) {
  .footer-center {
    width: 100%;
  }

  .footer-card-div {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-data-icons {
    font-size: var(--font-med);
  }
}

@media (max-width: 800px) {
  .footer-center {
    grid-template-columns: 1fr;
    text-align: center;
    width: 50%;
  }

  .footer-card {
    margin-bottom: 50px;
    border-bottom: 1px solid var(--text-color);
  }

  .footer-center .first-child {
    padding-bottom: 30px !important;
  }

  .footer-card ul {
    margin-top: 40px;
  }

  .footer-data {
    justify-content: center;
    flex-direction: column;
  }

  .footer-data-icons {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .footer-bottom {
    flex-direction: column;
    padding: 20px;
  }
  .footer-reserved {
    margin-top: 30px;
    display: block;
    text-align: center;
    padding-bottom: 60px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .footer-center {
    width: 100%;
  }
}
