.contact-form {
  padding: 70px 0;
}

.contact-form h1 {
  text-align: center;
  font-weight: 700;
  color: var(--white);
  font-size: var(--font-larger);
}

.contact-form p {
  text-align: center;
  width: 45%;
  margin: 10px auto;
  font-size: var(--font-medium);
  font-weight: 600;
  line-height: 25px;
  color: var(--text-light);
}

.main-form {
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80vh;
}

.main-form-left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  padding: 20px;
}

.form-left-div {
  height: 60vh;
}

.form-left-div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.main-form-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70vh;
}

.major-form {
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-light);
}

.form-group input {
  padding: 15px 12px;
  border-radius: 3px;
  border: 1px solid var(--text-color);
  outline: none;
  margin-bottom: 25px;
  width: 75%;
  color: var(--text-color);
}

.form-group input,
.form-group textarea::-webkit-input-placeholder {
  color: var(--text-color);
}

.form-group textarea {
  resize: none;
  height: 150px;
  overflow: auto;
  width: 75%;
  border-radius: 3px;
  padding: 12px;
  outline: none;
  border: 1px solid var(--text-color);
  color: var(--text-color);
}

.major-form button {
  padding: 20px;
  background: var(--blue-color);
  color: var(--white-color);
  border: none;
  outline: none;
  width: 75%;
  margin-top: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: var(--transition);
  border-radius: 3px;
}

.major-form button:hover {
  background: var(--blue-color);
  transition: var(--transition);
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .contact-form h1 {
    font-size: var(--font-larger);
  }

  .contact-form p {
    width: 45%;
    font-size: var(--font-medium);
  }

  .main-form-left {
    width: 50%;
    justify-content: flex-end;
  }

  .form-left-div {
    height: 70vh;
    width: 70%;
  }

  .main-form-right {
    width: 50%;
    justify-content: flex-start;
    padding-left: 40px;
  }
}

@media (max-width: 1000px) {
  .contact-form p {
    width: 80%;
  }

  .main-form-left {
    width: 40%;
  }

  .main-form-right {
    width: 60%;
  }

  .form-group input,
  .form-group textarea,
  .major-form button {
    width: 85%;
  }
}

@media (max-width: 800px) {
  .main-form-right {
    width: 90%;
    justify-content: center;
    margin: 0 auto;
  }

  .main-form-left {
    display: none;
  }

  .form-group input,
  .form-group textarea,
  .major-form button {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .contact-form h1 {
    font-size: var(--font-biggest);
  }

  .contact-form p {
    font-size: var(--font-mid);
  }

  .main-form-right {
    width: 95%;
  }
}
