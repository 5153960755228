.ahead {
  /* background: orange; */
  padding: 150px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ahead-left {
  width: 45%;
  margin-top: -25px;
}

.ahead-left h1 {
  color: var(--white);
  font-size: var(--font-large);
}

.ahead-left .ahead-left-list {
  line-height: 45px;
  margin-bottom: 10px;
  font-size: var(--font-med);
  color: var(--text-light);
  margin-top: -7px;
}

.check {
  color: var(--tertiary-color);
  margin-bottom: -2px;
  margin-right: 5px;
}

.ahead-left button {
  background: var(--blue-color);
  color: var(--white-color);
  padding: 15px 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: var(--transition);
  cursor: pointer;
  font-weight: 700;
}

.ahead-left button:hover {
  background: var(--secondary-color);
}

/* ahead right */
.ahead-right {
  width: 40%;
}

.video-section {
  width: 100%;
  /* height: 500px; */
}

/* THE RESPONSIVENESS */
@media (min-width: 1240px) {
  .ahead-left {
    width: 35%;
  }

  .ahead-left h1 {
    font-size: var(--font-larger);
  }
}

@media (max-width: 900px) {
  .ahead {
    flex-direction: column-reverse;
  }

  .ahead-left {
    width: 100%;
    text-align: center;
    margin-top: 25px;
  }

  .ahead-left .ahead-left-list {
    font-size: var(--font-smallest);
  }

  .ahead-right {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .ahead-left h1 {
    font-size: var(--font-bigger);
  }

  .ahead-left .ahead-left-list {
    font-size: var(--font-smallest);
  }

  .ahead-right {
    width: 100%;
  }
}
